@import './utils/styles/fonts.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html,
body {
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	scrollbar-width: none;
}

body {
	background-color: #0f0f0f;
	// background: url(../src/assets/images/doodle.jpeg);
	// background-repeat: no-repeat;
	// background-size: cover;
	overflow-x: hidden;
}
::-webkit-scrollbar {
	width: 0px;
}
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }
body {
	margin: 0;
	font-family: 'Marcellus', 'Lato', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.MuiDialog-root .MuiButton-label,
.MuiDialogTitle-root {
	color: white;
}

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #fff;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
