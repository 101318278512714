@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cedarville+Cursive&family=Share+Tech&family=Share+Tech+Mono&display=swap');

@font-face {
	font-family: 'Share Tech';
	font-weight: 400;
	src: url(../../assets/fonts/Share_Tech/ShareTech-Regular.ttf) format('ttf');
}

@font-face {
	font-family: 'Share Tech Mono';
	font-weight: 400;
	src: url(../../assets/fonts/Share_Tech_Mono/ShareTechMono-Regular.ttf)
		format('ttf');
}

@font-face {
	font-family: 'Bebas Neue';
	font-weight: 400;
	src: url(../../assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf) format('ttf');
}

@font-face {
	font-family: 'Cedarville Cursive';
	font-weight: 400;
	src: url(../../assets/fonts/Cedarville_Cursive/CedarvilleCursive-Regular.ttf)
		format('ttf');
}
